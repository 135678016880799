import styled from "styled-components";

const PageWrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: auto;
  scroll-snap-type: y proximity;
  overscroll-behavior-x: none;
`;

export default PageWrapper;
