/* eslint-disable no-undef */

import React, { useEffect, useRef } from "react";
import styled, { css } from "styled-components";
// import styled, { css, keyframes } from "styled-components";
import "styled-components/macro";
import smoothscroll from "smoothscroll-polyfill";

/* Arrow
 * Svg arrow that animates away after click
 * direction -  string - up | down | left | right
 * onClick - function - callback function whebn element is clicked.
 */
function Arrow(props) {
  console.log("mount");
  const svgRef = useRef();
  // const isActiveRef = useRef(false);

  useEffect(() => {
    smoothscroll.polyfill();
  }, []);

  const handleClick = () => {
    svgRef.current.style.transform = cssTransforms[props.direction].clicked;
    svgRef.current.style.opacity = "0";
    svgRef.current.style.transition = `all 300ms ease-in`;

    // reset position after animation
    setTimeout(() => {
      svgRef.current.style.transition = "none";
      svgRef.current.style.transform = cssTransforms[props.direction].default;
      if (props.callback) props.callback();
    }, 300);

    // fade back in
    setTimeout(() => {
      svgRef.current.style.transition = "all 300ms ease-in";
      svgRef.current.style.opacity = "1";
    }, 1000);

    // if (isActiveRef.current) return;
    // svgRef.current.classList.add("--active");
    // isActiveRef.current = true;

    // reset style
    setTimeout(() => {
      svgRef.current.style = null;
      // isActiveRef.current = false;
      // svgRef.current.classList.remove("--active");
    }, 1300);
  };

  return (
    <Svg
      ref={svgRef}
      viewBox="0 0 17.41 47.41"
      onClick={handleClick}
      {...props}
    >
      <polygon
        fill="#ffffff"
        points="17.41,38.71 16,37.29 9.71,43.59 9.71,0 7.71,0 7.71,43.59 1.41,37.29 0,38.71 8.71,47.41 "
      />
    </Svg>
  );
}

// const heartBeat = (props) => {
//   return keyframes`
//     0% { transform: ${css`
//       ${cssTransforms[props.direction].default};
//     `}
//     }
//     100% { transform: ${css`
//       ${cssTransforms[props.direction].active};
//     `}
//     }
//   `;
// };

const Svg = styled.svg`
  width: 25px;
  height: 69px;
  min-height: 69px;
  opacity: 1;
  transition: all 200ms ease-out;

  ${(props) => css`
    transform: ${cssTransforms[props.direction].default};
    &:hover {
      transform: ${cssTransforms[props.direction].hover};
    }
  `}/* &.--active {
    animation: ${(props) =>
    css`
      ${heartBeat(props)} 3s infinite;
    `};
  } */
`;

const cssTransforms = {
  up: {
    default: "translateY(0) rotate(180deg)",
    hover: "translateY(-10%) rotate(180deg)",
    clicked: "translateY(-100%) rotate(180deg)",
  },
  right: {
    default: "translateY(0) rotate(-90deg)",
    hover: "translateX(20%) rotate(-90deg)",
    clicked: "translateX(200%) rotate(-90deg)",
  },
  down: {
    default: "translateY(0)",
    hover: "translateY(10%)",
    clicked: "translateY(100%)",
  },
  left: {
    default: "translateY(0) rotate(90deg)",
    hover: "translateX(-20%) rotate(90deg)",
    clicked: "translateX(-200%) rotate(90deg)",
  },
};

export default Arrow;
