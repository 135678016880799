import React from "react";
import styled from "styled-components";

const LinkUnderlined = (props) => {
  return (
    <Link {...props}>
      {props.children}
      <svg viewBox="0 0 1200 60" preserveAspectRatio="none">
        <path d="M0,56.5c0,0,298.666,0,399.333,0C448.336,56.5,513.994,46,597,46c77.327,0,135,10.5,200.999,10.5c95.996,0,402.001,0,402.001,0"></path>
      </svg>
    </Link>
  );
};

const Link = styled.a`
  position: relative;
  display: inline-block;
  vertical-align: bottom;
  overflow: hidden;
  text-decoration: none;
  color: #fff;

  svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 300%;
    height: 100%;
    pointer-events: none;
    fill: none;
    stroke: currentColor;
    stroke-width: 3px;
    transform: translate3d(-66.6%, 0, 0);
  }

  &:hover svg,
  &:active svg,
  &:visited svg,
  &:focus svg {
    transform: translate3d(0%, 0, 0);
    transition: all 1000ms cubic-bezier(0, 0.25, 0.5, 1);
  }
`;

export default LinkUnderlined;
