import styled from "styled-components";
import Text from "../components/Text/Text";
import video from "../images/404.mp4";
import Arrow from "../components/Arrow/Arrow";

const Page404 = () => {
  return (
    <Outer>
      <Text>Error 404 - page not found</Text>
      <Video width="250" autoPlay playsInline muted loop>
        <source src={video} type="video/mp4" />
        Sorry, your browser doesn't support embedded videos.
      </Video>
      <Text>
        <a href="/">
          <span> Go home</span> <br />
          <Arrow direction="left" />
        </a>
      </Text>
    </Outer>
  );
};

const Outer = styled.div`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  background: #000;
  color: #fff;
  padding: 20px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  line-height: 1.2;

  a {
    color: #fff;
  }
`;

const Video = styled.video`
  width: 100%;
  max-width: 640px;
`;

export default Page404;
