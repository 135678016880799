import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "@fontsource/montserrat";
import "./App.css";
import PageHome from "./pages/index";
import PageMenu from "./pages/menu";
import Page404 from "./pages/404";

function App() {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<PageHome />} />
        <Route exact path="/menu" element={<PageMenu />} />
        <Route path="*" element={<Page404 />} />
      </Routes>
    </Router>
  );
}

export default App;
