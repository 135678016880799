import styled from "styled-components";
// import P from "../P/P";

const Component = (props) => {
  const Tag = props.variant || "p";

  const Text = styled(Tag)`
    font-size: clamp(1rem, 2vw, 1.5rem);
    color: white;
    line-height: 1.4;
  `;

  return <Text className={props.className}>{props.children}</Text>;
};

export default Component;
