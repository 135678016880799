import { useState } from "react";
import styled from "styled-components";

const SectionInner = styled.div`
  min-height: calc(var(--vh, 1vh) * 100);
  padding: 16px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  transition: padding 300ms ease;

  @media (min-width: 360px) {
    padding: 30px;
    transition: padding 300ms ease;
  }

  @media (min-width: 768px) {
    padding: 40px;
    transition: padding 300ms ease;
  }

  @media (min-width: 1024px) {
    padding: 50px;
    transition: padding 300ms ease;
  }

  @media (min-width: 1200px) {
    padding: 60px;
    transition: padding 300ms ease;
  }
`;

const It = (props) => {
  const isClient = !!(
    typeof window !== "undefined" &&
    window.document &&
    window.document.createElement
  );
  useState(() => {
    if (isClient) {
      set();
      window.addEventListener("resize", set);
    }

    function set() {
      // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
      const vh = window.innerHeight * 0.01;
      // Then we set the value in the --vh custom property to the root of the document
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    }

    // return isClient && window.removeEventListener("resize", set);
  }, []);

  return <SectionInner {...props} />;
};

export default It;
