import React from "react";
import styled from "styled-components";
// import poster from "./assets/poster.jpg";
import actualVideo from "./assets/background-2mb.mp4";

const VideoBackground = (props) => {
  return (
    <Outer>
      {/* <video autoPlay loop muted poster={poster}> */}
      <video autoPlay playsInline loop muted>
        <source src={actualVideo} type="video/mp4" />
      </video>
      <Overlay />
    </Outer>
  );
};

const Outer = styled.div`
  position: fixed;
  height: 100vh;
  width: 100vw;
  box-sizing: border-box;
  pointer-events: none;

  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.5);
  pointer-events: none;
`;

export default VideoBackground;
