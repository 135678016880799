import styled from "styled-components";

const Section = styled.section`
  position: relative;
  z-index: 2;
  scroll-snap-align: start;
  box-sizing: border-box;
`;

export default Section;
