import React, { useEffect } from "react";
import styled from "styled-components";
import "styled-components/macro";
import PreLoadAnimation from "../PreLoadAnimation/PreLoadAnimation";

const MailingList = () => {
  useEffect(() => {
    initSproutSend();
  }, []);

  return (
    <FormContainer>
      <FormPlaceholder id="CONTACTA_5f91552c1c1127f2e4e1601a">
        <PreLoadAnimation />
      </FormPlaceholder>
    </FormContainer>
  );
};

/* initSproutSend
 * add 'Sprout send' script tag and inject form into placeholder
 */
function initSproutSend() {
  var script = document.createElement("script");
  script.type = "text/javascript";
  script.src = "https://forms.contacta.io/5f91552c1c1127f2e4e1601a.js";
  document.getElementsByTagName("head")[0].appendChild(script);
}

const FormContainer = styled.div`
  max-width: 320px;
  text-align: left;
  /* font-size: 1rem; */
  font-family: "Montserrat", sans-serif;
  font-size: clamp(1rem, 2vw, 1.5rem);
  font-size: 1rem !important;

  label {
    display: block;
    width: 100%;
    margin-bottom: 0.5em;
    /* font-size: 1.2em; */
    font-size: 1rem !important;
    color: #fff;
  }

  input,
  select {
    width: 100%;
    font-family: "Montserrat", sans-serif;
    font-size: clamp(1rem, 2vw, 1.5rem);
    font-size: 1rem !important;
    padding: 0.5em;
    box-sizing: border-box;
    border-color: transparent !important;
    background-color: #fff;
  }

  select {
    color: #757575;
  }

  .contacta-webform-table .contacta-col {
    padding-left: 0;
    padding-right: 0;
  }

  .contacta-webform-table .error-msg {
    font-style: normal;
    font-size: clamp(1rem, 2vw, 1.5rem);
    font-size: 1rem !important;
    line-height: 2;
    color: #ed125f;
    outline: none;
  }

  .submitBtn button {
    width: auto !important;
    border: 0;
    padding: 0 !important;
    color: #fff !important;
    font-family: "Montserrat", sans-serif;
    font-size: 0 !important;
    line-height: 0 !important;
    letter-spacing: normal !important;
    background-color: transparent !important;
    text-align: left !important;

    border-width: 2px !important;
    border-radius: 0 !important;
    border-color: #fff !important;
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;

    font {
      display: none;
    }

    &:before {
      font-size: clamp(1rem, 2vw, 1.5rem) !important;
      /* font-size: 1rem !important; */
      font-weight: 700 !important;
      line-height: 1.4 !important;
      text-transform: uppercase !important;
      content: "S";
    }

    &:after {
      font-size: clamp(1rem, 2vw, 1.5rem) !important;
      /* font-size: 1rem !important; */
      font-weight: 700 !important;
      line-height: 1.4 !important;
      text-transform: lowercase !important;
      content: "ubmit";
    }

    &:hover {
      cursor: pointer;
    }
  }

  .submission-message-info {
    font-size: 1.6em !important;
    font-size: clamp(1.5rem, 2vw, 2rem) !important;
    color: #fff;
    font-weight: 300;
    div {
      text-align: left !important;
    }
  }
`;

const FormPlaceholder = styled.div`
  height: 623px;
`;

export default MailingList;
