import React, { useState, useEffect, useRef, memo } from "react";
import styled from "styled-components";
import "styled-components/macro";
import Section from "../Section/Section";
import SectionInner from "../SectionInner/SectionInner";
import MailingList from "../MailingList/MailingList";
import LinkUnderlined from "../LinkUnderlined/LinkUnderlined";
import Arrow from "../Arrow/Arrow";
import Text from "../Text/Text";

const MainSection = (props) => {
  const StyledSectionInner = styled(SectionInner)`
    overflow-y: auto;
  `;

  const StyledSocialLinks = styled(SocialLinks)`
    margin-top: auto;
  `;

  return (
    <Section id="section-main">
      <StyledSectionInner>
        <Arrow
          direction="up"
          callback={() => {
            document
              .querySelector("#section-intro")
              .scrollIntoView({ behavior: "smooth" });
          }}
        />
        <MainContent initialItem={props.initialItem} />
        <StyledSocialLinks />
      </StyledSectionInner>
    </Section>
  );
};

const MainContent = () => {
  const [activeItem, setActiveItem] = useState(false);
  const prevActiveItem = useRef(false);
  const MainContentRef = useRef();

  // runs everytime activeItem is modified
  useEffect(() => {
    const elemMainContent = MainContentRef.current;

    // 1. if somthing is open, close it
    if (prevActiveItem.current !== false) {
      const elemPrev = elemMainContent.querySelector(
        `#${prevActiveItem.current} .${prevActiveItem.current}-item-content`
      );
      const elemPrevInner = elemMainContent.querySelector(
        `#${prevActiveItem.current} .${prevActiveItem.current}-item-inner`
      );

      if (activeItem === false) {
        // if this is the only one open, animate content closed
        elemPrev.style.height = `${elemPrevInner.offsetHeight}px`;
        setTimeout(() => {
          elemPrev.style.height = 0;
        }, 100);
        elemPrev.style.opacity = 0;
      } else {
        // if another is opening, close the prev immediatly without animation
        elemPrev.style = null;
      }

      // reset prevActiveItem
      prevActiveItem.current = false;
    }

    // 1. if we need to open something, open it
    if (activeItem !== false) {
      const elemActive = elemMainContent.querySelector(
        `#${activeItem} .${activeItem}-item-content`
      );
      const elemActiveInner = elemMainContent.querySelector(
        `#${activeItem} .${activeItem}-item-inner`
      );
      // animate content div to the correct height
      elemActive.style.height = `${elemActiveInner.offsetHeight}px`;
      // fade in
      elemActive.style.opacity = 1;
      // after animation completes
      setTimeout(() => {
        // set height to auto after animation finishes
        elemActive.style.height = "auto";
      }, 300);
      // update prevActiveItem
      prevActiveItem.current = activeItem;
    }
  }, [activeItem]);

  const handleItemClick = (id) => {
    setActiveItem(prevActiveItem.current === id ? false : id);
  };

  return (
    <MainContentOuter
      ref={MainContentRef}
      id="info-links"
      activeItem={activeItem}
    >
      <Item
        id="menu"
        title="Menu"
        isActive={activeItem === "menu"}
        setActiveItem={handleItemClick}
      >
        <Text>
          <LinkUnderlined href="/menu" target="_blank">
            Eat in
          </LinkUnderlined>
          <br />
          <br />
          <LinkUnderlined
            href="https://www.mryum.com/murray/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Take away
          </LinkUnderlined>
        </Text>
      </Item>

      <Item
        id="location"
        title="Location"
        isActive={activeItem === "location"}
        setActiveItem={handleItemClick}
      >
        <Text>
          8 Murray Street, <br />
          Abbottsford Vic. <br />
          <br />
          <LinkUnderlined
            href="https://goo.gl/maps/VVtafKdYZi12"
            target="_blank"
            rel="noopener noreferrer"
          >
            See map.
          </LinkUnderlined>
          <br />
          <br />
          <LinkUnderlined
            href="https://www.google.com.au/maps/dir//8+Murray+St,+8+Murray+St,+Abbotsford+VIC+3067/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Get directions.
          </LinkUnderlined>
        </Text>
      </Item>

      <Item
        id="contact"
        title="Contact"
        isActive={activeItem === "contact"}
        setActiveItem={handleItemClick}
      >
        <Text>
          Call us on <br />
          <LinkUnderlined href="tel:0399391118">(03) 9939 1118</LinkUnderlined>
          <br />
          <br />
          Email us on <br />
          <LinkUnderlined href="mailto:theguys@8murray.com">
            theguys@8murray.com
          </LinkUnderlined>
        </Text>
      </Item>

      <Item
        id="hours"
        title="Opening hours"
        isActive={activeItem === "hours"}
        setActiveItem={handleItemClick}
      >
        <Text>Everyday 8am to 2:30pm</Text>
      </Item>

      <Item
        id="mailing-list"
        title="Mailing list"
        isActive={activeItem === "mailing-list"}
        setActiveItem={handleItemClick}
      >
        <Text>
          Want cool stuff for your birthday? <br />
          Subscribe to our mailing list.
        </Text>
        <MailingList />
      </Item>
    </MainContentOuter>
  );
};

const MainContentOuter = styled.div`
  margin-top: 2rem;
  margin-bottom: auto;
`;

const Item = memo(
  (props) => {
    const Outer = styled.div`
      margin-bottom: clamp(1.5rem, 3vw, 4rem);
    `;

    const ItemText = styled.button`
      margin: 0;
      padding: 0;
      cursor: pointer;
      font-size: clamp(1.2rem, 2vw, 2rem);
      font-weight: bold;

      // reset button styles
      border: 0;
      background: none;
      padding: 0;
    `;

    return (
      <Outer id={props.id}>
        <ItemText>
          <LinkUnderlined
            onClick={() => {
              props.setActiveItem(props.id);
            }}
          >
            {props.title}
          </LinkUnderlined>
        </ItemText>

        <ItemContent id={props.id}>{props.children}</ItemContent>
      </Outer>
    );
  },
  () => {
    return true;
  }
);

const ItemContent = (props) => {
  const Outer = styled.div`
    height: 0px;
    overflow: hidden;
    transition: all 300ms ease-out;
    opacity: 0;
  `;

  const Inner = styled.div`
    padding: clamp(1.5rem, 2vw, 4rem);
  `;

  return (
    <Outer className={`${props.id}-item-content`}>
      <Inner className={`${props.id}-item-inner`}>{props.children}</Inner>
    </Outer>
  );
};

const SocialLinks = () => {
  const data = [
    { name: "Instagram", href: "https://www.instagram.com/8murrayst/" },
    { name: "Facebook", href: "https://www.facebook.com/8murrayst/" },
  ];

  const Outer = styled.div`
    margin-top: auto;
    p {
      font-size: 1em;
    }
  `;

  return (
    <Outer>
      {data.map((item) => (
        <Text key={item.name}>
          <LinkUnderlined
            href={item.href}
            target="_blank"
            rel="noopener noreferrer"
          >
            {item.name}
          </LinkUnderlined>
        </Text>
      ))}
    </Outer>
  );
};

export default MainSection;
