import React, { useRef } from "react";
import "styled-components/macro";
import PageWrapper from "../components/PageWrapper/PageWrapper";
import VideoBackground from "../components/VideoBackground/VideoBackground";
import IntroSection from "../components/IntroSection/IntroSection";
import MainSection from "../components/MainSection/MainSection";

//Page /////////////////////////////////////////////////////////////////////////
const Page = () => {
  const wrapperRef = useRef();

  return (
    <PageWrapper ref={wrapperRef}>
      <VideoBackground />
      <IntroSection scrollElem={wrapperRef} />
      <MainSection />
    </PageWrapper>
  );
};

export default Page;
