import React, { useEffect, useRef, useState, memo } from "react";
import styled, { keyframes } from "styled-components";
import "styled-components/macro";
import Section from "../Section/Section";
import SectionInner from "../SectionInner/SectionInner";
import { ReactComponent as SvgLogo } from "../../images/logo.svg";
import LinkUnderlined from "../LinkUnderlined/LinkUnderlined";
import Arrow from "../Arrow/Arrow";
import P from "../P/P";
import OpenStatus from "../OpenStatus/OpenStatus";

const IntroSection = (props) => {
  return (
    <Section id="section-intro">
      <StyledSectionInner>
        <Logo scrollElem={props.scrollElem} />
        <TitleText as="h1">
          Glorious courtyard, <BrDesktop />
          divine coffee,
          <br />
          exceptional food &amp; <BrDesktop />
          endless good vibes.
        </TitleText>
        <SubTitleText>
          <OpenStatus />
        </SubTitleText>
        <Booking />
        <SubTitleText>
          <LinkUnderlined href="/menu" target="_blank">
            See the menu.
          </LinkUnderlined>
          <br />
          <LinkUnderlined
            href="https://www.mryum.com/murray/"
            target="_blank"
            rel="noopener noreferrer"
            css="margin-top:12px;"
          >
            Order takeaway.
          </LinkUnderlined>
        </SubTitleText>
        <br />
        <Arrow
          direction="down"
          callback={() => {
            document
              .querySelector("#section-main")
              .scrollIntoView({ behavior: "smooth" });
          }}
        />
      </StyledSectionInner>
    </Section>
  );
};

const StyledSectionInner = styled(SectionInner)`
  justify-content: flex-end;
`;

const Logo = (props) => {
  const outerRef = useRef();
  const svgRef = useRef();

  const bodyScrollListener = () => {
    svgRef.current.style.transform = `rotate(${(
      -props.scrollElem.current.scrollTop / 3.5
    ).toFixed(0)}deg)`;

    outerRef.current.style.transform = `translateX(${(
      -props.scrollElem.current.scrollTop / 2.5
    ).toFixed(0)}px)`;
  };

  useEffect(() => {
    if (props.scrollElem.current)
      props.scrollElem.current.addEventListener("scroll", bodyScrollListener);
    return () => {
      if (props.scrollElem.current)
        props.scrollElem.current.removeEventListener(
          "scroll",
          bodyScrollListener
        );
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Outer ref={outerRef}>
      <SvgLogo ref={svgRef} />
    </Outer>
  );
};

const animationRotate = keyframes`
  from { transform: rotate(0deg); }
  to { transform: rotate(-360deg); }
`;

const Outer = styled.div`
  width: 150px;

  #text path {
    transform: rotate(100deg);
    transform-origin: 50% 50%;
    animation: ${animationRotate} 15s linear infinite;
  }

  svg {
    width: 100%;
    height: 100%;
  }
`;

const BrDesktop = styled.br`
  @media (min-width: 768px) {
    display: none;
  }
`;

const TitleText = styled(P)`
  font-size: clamp(1.2rem, 0.6429rem + 2.4762vw, 2.5rem);
`;

const SubTitleText = styled(P)`
  font-size: clamp(1.2rem, 0.8571rem + 1.5238vw, 2rem);
  margin-bottom: 0;
`;

const Booking = memo(
  (props) => {
    const widgetContainerRef = useRef();
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
      const script = document.createElement("script");
      const isIframe = false;

      // standard
      script.src = `//www.opentable.com.au/widget/reservation/loader?rid=150293&type=standard&theme=standard&iframe=${isIframe}&domain=comau&lang=en-AU&newtab=false&ot_source=Restaurant%20website`;

      // tall
      // script.src =
      //   "//www.opentable.com.au/widget/reservation/loader?rid=150293&type=standard&theme=tall&iframe=true&domain=comau&lang=en-AU&newtab=false&ot_source=Restaurant%20website";

      // wide
      // script.src =
      //   "//www.opentable.com.au/widget/reservation/loader?rid=150293&type=standard&theme=wide&iframe=true&domain=comau&lang=en-AU&newtab=false&ot_source=Restaurant%20website";

      // button
      // script.src =
      //   "//www.opentable.com.au/widget/reservation/loader?rid=150293&type=button&theme=standard&iframe=true&domain=comau&lang=en-AU&newtab=false&ot_source=Restaurant%20website";

      script.async = true;
      widgetContainerRef.current.appendChild(script);
    }, []);

    function handleClick() {
      if (isOpen === true) {
        widgetContainerRef.current.style.overflow = "hidden";
        setIsOpen(false);
      } else {
        setTimeout(() => {
          widgetContainerRef.current.style.overflow = "unset";
        }, 300);
        setIsOpen(true);
      }
    }

    return (
      <BookingOuter isOpen={isOpen}>
        <BookingText>
          <LinkUnderlined onClick={handleClick}>Make a booking</LinkUnderlined>.
        </BookingText>
        <WidgetContainer
          className="widget-container"
          ref={widgetContainerRef}
          isOpen={isOpen}
        />
      </BookingOuter>
    );
  },
  () => {
    return true;
  }
);

const BookingOuter = styled.span`
  display: inline-block;
  .widget-container {
    height: ${(props) => (props.isOpen === true ? "200px" : "0")};
    opacity: ${(props) => (props.isOpen === true ? "1" : "0")};
    margin-bottom: ${(props) => (props.isOpen === true ? "2rem" : "0")};
  }
`;

const BookingText = styled(P)`
  font-size: clamp(1.2rem, 0.8571rem + 1.5238vw, 2rem);
  margin-top: 0;
  cursor: pointer;
`;

const WidgetContainer = styled.span`
  display: block;
  transition: all 300ms ease;
  overflow: hidden;

  #ot-widget-container1 {
  }

  .ot-dtp-picker {
    padding: 0;
    background: transparent;
  }

  .ot-title,
  .ot-powered-by {
    display: none;
  }

  .ot-dtp-picker .ot-dtp-picker-form {
    padding: 0;
    background: transparent;
    border: none;
  }

  .ot-dtp-picker .ot-date-picker .ot-dtp-picker-select {
    font-size: 16px;
  }

  .ot-date-picker,
  .ot-time-picker,
  .ot-party-size-picker {
    background-color: #fff;
    font-family: "Montserrat", sans-serif;
    margin-bottom: 0.4rem !important;
  }

  input[type="submit"].ot-button {
    width: auto;
    height: auto;
    padding: 0;
    color: #fff;
    font-family: "Montserrat", sans-serif;
    font-size: clamp(1rem, 2vw, 1.5rem);
    font-weight: 700;
    line-height: 1.4;
    background: transparent;
    border: none;
    border-bottom: 2px solid #fff;
    box-shadow: none;
  }
`;

export default IntroSection;
