import styled from "styled-components";
import Text from "../components/Text/Text";
import pdf from "../images/menu-winter-2023.pdf";

const MenuPage = () => {
  if (typeof window !== "undefined") window.location.href = pdf;
  return (
    <Outer>
      <Text>Were bringing you the menu!</Text>
      <Text>
        If you are not redirected automagically, <a href={pdf}>click here</a>.
      </Text>
    </Outer>
  );
};

const Outer = styled.div`
  background: #000;
  color: #fff;
  padding: 20px;

  a {
    color: #fff;
  }
`;

export default MenuPage;
