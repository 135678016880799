const OpenStatusText = () => {
  const openingHours = [
    ["8:00", "14:30"], // sunday
    ["8:00", "14:30"], // monday
    ["8:00", "14:30"], // tuesday
    ["8:00", "14:30"], // wednesday
    ["8:00", "14:30"], // thursday
    ["8:00", "14:30"], // friday
    ["8:00", "14:30"], // saturday
  ];
  const openStatus = openStatusText(openingHours);
  return <>{openStatus}</>;
};

export default OpenStatusText;

/* OpenStatusText
 * text showing the open status. eg.
 *   "Opening today at 8am"
 *   "Open today until 2:30pm"
 *   "Open tommorrow at 8am"
 *
 * @param openingHours - array - eg.
 *  [
 *   ["8:00", "14:30"], // sunday
 *   ["8:00", "14:30"], // monday
 *   ["8:00", "14:30"], // tuesday
 *   ["8:00", "14:30"], // wednesday
 *   ["8:00", "14:30"], // thursday
 *   ["8:00", "14:30"], // friday
 *   ["8:00", "14:30"], // saturday
 *  ]
 *
 * return - string
 */

function openStatusText(openingHours) {
  const currentDate = new Date();
  const currentDay = currentDate.getDay();
  const currentHour = currentDate.getHours();
  const currentMins = currentDate.getMinutes();
  const currentTime = `${currentHour}${currentMins}`;

  const todayOpenTime = timeObj(openingHours[currentDay][0]);
  const todayOpenTimeFormatted = formatTime(
    todayOpenTime.hour,
    todayOpenTime.min
  );

  const todayCloseTime = timeObj(openingHours[currentDay][1]);
  const todayCloseTimeFormatted = formatTime(
    todayCloseTime.hour,
    todayCloseTime.min
  );

  const tomorrow = new Date(currentDate);
  tomorrow.setDate(tomorrow.getDate() + 1);
  const tomorowDay = tomorrow.getDay();

  const tomorrowOpenTime = timeObj(openingHours[tomorowDay][0]);
  const tomorrowOpenTimeFormatted = formatTime(
    tomorrowOpenTime.hour,
    tomorrowOpenTime.min
  );

  const isOpen = currentTime >= todayOpenTime.full && currentTime < todayCloseTime.full; //prettier-ignore
  const isBeforeOpen = !isOpen & (currentTime < todayOpenTime.full);

  const message = isBeforeOpen
    ? `Opening today at ${todayOpenTimeFormatted}.`
    : isOpen
    ? `Open today until ${todayCloseTimeFormatted}.`
    : `Open tomorrow at ${tomorrowOpenTimeFormatted}.`;

  return message;
}

/* timeObj
 * convert a 24hr time string into an obj for easy use
 * eg. { full:830, hour:8, min:30}
 *
 * @param str - string - between 0:00 and 23:59
 * return - obj - { full:number, hour:number, min:number}
 */
function timeObj(str) {
  return {
    full: Number(str.replace(":", "")),
    hour: Number(str.split(":")[0]),
    min: Number(str.split(":")[1]),
  };
}

/* formatTime
 * convert 24hr time into a nice human readable format
 * eg. 800 to 8am, 530 to 5:30pm
 *
 * @param hour - number - between 0 and 23
 * @param min - number - between 0 and 59
 * return - string
 *
 */
function formatTime(hour, min) {
  return `${hour % 12 || 12}${min > 0 ? `:${min}` : ""}${
    hour >= 12 ? "pm" : "am"
  }`;
}
