import React from "react";
import styled from "styled-components";
import "styled-components/macro";

const PreloadAnimation = (props) => {
  const color = props.color || "#ffffff";
  const size = props.size || "24px";

  const Loader = styled.div`
    border-radius: 50%;
    /* margin: 0 auto; */
    /* margin-top: 5em; */
    border-radius: 100em 100em 0 0;
    background: #f00;
    transform-origin: bottom;
    animation: eating-top 0.5s infinite;

    &,
    &::before {
      width: ${size};
      height: calc(${size} / 2);
      background: ${color};
    }

    &::before {
      content: "";
      display: block;
      margin-top: calc(${size} / 2);
      position: absolute;
      transform-origin: top;
      border-radius: 0 0 100em 100em;
      transform: rotate(80deg);
      animation: eating-bottom 0.5s infinite;
    }

    @keyframes eating-top {
      0% {
        transform: rotate(-40deg);
      }
      50% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(-40deg);
      }
    }

    @keyframes eating-bottom {
      0% {
        transform: rotate(80deg);
      }
      50% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(80deg);
      }
    }
  `;

  return <Loader {...props} />;
};

export default PreloadAnimation;
